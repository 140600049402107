import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { isEmpty, getOr } from "lodash/fp";

import Defender from "helpers/defender";
import SignOut from "./../../api/auth/sign_out";
import User from "./../../api/users";
import queryString from 'query-string';
import NavbarStandard from './front/NavbarStandard';
import Footer from './front/Footer';
import homeHero from 'assets/img/sp-images/home/hero-doctor-desktop.jpg';
import homeExperts from 'assets/img/sp-images/home/empathy-lg.jpg';
import homeExpertsm from 'assets/img/sp-images/home/empathy-sm.jpg';
import homeCTA from 'assets/img/sp-images/home/quote__icon.png';
import homeRPMS from 'assets/img/sp-images/home/rpms-ehr-logo.png';
import tileDive from 'assets/img/sp-images/home/dive@2x.png';
import tileDiscover from 'assets/img/sp-images/home/discover@2x.png';
import tileDevelop from 'assets/img/sp-images/home/develop@2x.png';
import tileCycle from 'assets/img/sp-images/home/cycle-managment.png';
import tileCoding from 'assets/img/sp-images/home/coding.png';
import tileBilling from 'assets/img/sp-images/home/billing.png';
import tileAccounts from 'assets/img/sp-images/home/accounts.png';
import bg1 from 'assets/img/sp-images/home/home_chart.svg';
import bg2 from 'assets/img/sp-images/home/bg2.svg';
import potential1 from 'assets/img/sp-images/home/potential--1.png';
import potential2 from 'assets/img/sp-images/home/potential--2.png';
import potential3 from 'assets/img/sp-images/home/potential--3.png';
import homeBorder from 'assets/img/sp-images/home/border.svg';
import dash1 from 'assets/img/sp-images/dashboard1.png';
import dash2 from 'assets/img/sp-images/dashboard2.png';
import { Link } from 'react-router-dom';

const Site = ({ }) => {

	const navigate = useNavigate();

	const [expertsImage, setExpertsImage] = useState(
		window.innerWidth > 991 ? homeExperts : homeExpertsm
	);

	// useEffect to handle window resizing and updating the background image
	useEffect(() => {
		const handleResize = () => {
			setExpertsImage(window.innerWidth > 991 ? homeExperts : homeExpertsm);
		};

		window.addEventListener('resize', handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);



	const logOut = (e) => {
		SignOut.destroy().then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 201) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				localStorage.removeItem('dashboardName')
				localStorage.removeItem('dashboardId')
				localStorage.removeItem('dashboardSubPage')
				localStorage.removeItem('dashboardCsvUploaded')
				localStorage.removeItem('dashboardStartDate')
				localStorage.removeItem('dashboardEndDate')
				localStorage.removeItem('dashboardStatic')
				localStorage.removeItem('option')
				Defender.logout();
				navigate("/");
			}
		})
	}

	useEffect(() => {
		const parsed = queryString.parse(location.search);
		let data = {}
		data["organization_id"] = parsed['org']
		data["user_id"] = parsed['user']
		if (!isEmpty(parsed['org']) && !isEmpty(parsed['user'])) {
			User.updateOrganization({ user: data }).then((response) => {
				if (
					(getOr("", "status", response) === 200 ||
						getOr("", "status", response) === 204) &&
					isEmpty(getOr({}, "data.errors", response))
				) {
					if (!isEmpty(Defender.currentUser())) {
						User.fetch(getOr(0, 'id', Defender.currentUser())).then((response) => {
							if (
								(getOr("", "status", response) === 200 ||
									getOr("", "status", response) === 204) &&
								isEmpty(getOr({}, "data.errors", response))) {
								Defender.setCurrentUser(getOr({}, 'data.user', response))
								window.location.href = "/"
							}
						})
					}
				}
			})
		}
	}, []);
	return (
		<div className="home">
			<NavbarStandard />
			<div className="content content--front">
				<section
					className="hero py-0"
					style={{ backgroundImage: `url(${homeHero})` }}
				>
					<div className="container">
						<div className="hero__content">
							<h3 className="overline">Native-owned and Native-led</h3>
							<h1 className="hero__title">Unlock your Tribal Healthcare Facility’s Full&nbsp;Potential</h1>
							<div className="subtitle subtitle--home">
								<p>
									We are here to help empower healthcare leaders to drive excellence, overcome challenges, and create thriving communities with our proprietary expert-driven consulting&nbsp;solutions.
								</p>
							</div>
							<div className='d-flex gap-3'>
								<a href="/contact" className="btn btn-primary btn--3d btn--rg btn--home">Get Started</a>
							</div>
						</div>
					</div>
				</section>










				<section
					className="experts py-0"
					style={{ backgroundImage: `url(${bg1})` }} >
					<div className="container">
						<div className="experts__right bg-fill bg-fill--experts">
							<div
								className="bg-fill__img"
								style={{ backgroundImage: `url(${expertsImage})` }}
							/>
						</div>
						<div className="experts__left">
							<h3 className="overline overline--experts">We are your expert&nbsp;guide</h3>
							<h2 className="section__title section__title--experts">Get Compassionate Guidance From Our Experienced Team</h2>
							<div className="subtitle subtitle--home">
								<p>At Bernie Yazzie Consulting, we understand that you may feel distant from your goals and aspirations, but rest assured, we are here to bridge that gap.</p>
								<p>Our Native-owned and Native-led team has supported numerous Tribal healthcare facilities through similar struggles, and we bring a judgment-free mindset, aiming only to assist, uplift, and empower you along the way.</p>
							</div>
						</div>
					</div>
				</section>





				{/* <section className="testimonials">
					<div className="container">
						<div className="testimonials__wrapper">
							<h2 className="overline overline--testimonials">What our partners&nbsp;say</h2>
							<h2 className="section__title section__title--testimonials">Your Catalysts to Superior Patient&nbsp;Care</h2>
							<div className="subtitle subtitle--home subtitle--testimonials">
								<span>With decades of expertise</span> in the health services industry, the team at Bernie Yazzie Consulting intimately understand the challenges you encounter. Trust our seasoned guidance to navigate your path towards excellence and make a lasting impact on the well-being of your patients and communities.
							</div>
						</div>
						<div className="testimonials__grid">
						<div className="testimonials__item">
							<div className="testimonials__quote">
							ornare lectus sit amet est placerat in egestas erat imperdiet se.
							</div>
							<div className="testimonials__meta">
								<img className="testimonials__photo" src="https://placebear.com/251/251" />
								<div className="testimonials__info">
									<div className="testimonials__name">Youssef</div>
									<div className="testimonials__title">Front-End Wizard</div>
									<div className="testimonials__additional">Coder</div>
								</div>
							</div>
						</div>
						<div className="testimonials__item">
							<div className="testimonials__quote">
							tempor orci eu lobortis elementum nibh tellus molestie nunc non blandit massa enim nec dui nunc mattis enim ut tellus elementum sagittis vitae et leo duis ut diam quam nulla porttitor massa id neque aliquam vestibulum morbi blandit cursus riset	.
							</div>
							<div className="testimonials__meta">
								<img className="testimonials__photo" src="https://placebear.com/255/255" />
								<div className="testimonials__info">
									<div className="testimonials__name">Youssef</div>
									<div className="testimonials__title">Front-End Wizard</div>
									<div className="testimonials__additional">Coder</div>
								</div>
							</div>
						</div>
						<div className="testimonials__item">
							<div className="testimonials__quote">
							sit amet risus nullam eget felis eget nunc lobortis mattis aliquam faucibus purus in massa tempor nec feugiat nisl pretium fusce id velit ut tortor pretium viverra suspendisse potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est.
							</div>
							<div className="testimonials__meta">
								<img className="testimonials__photo" src="https://placebear.com/254/254" />
								<div className="testimonials__info">
									<div className="testimonials__name">Youssef</div>
									<div className="testimonials__title">Front-End Wizard</div>
									<div className="testimonials__additional">Coder</div>
								</div>
							</div>
						</div>
						<div className="testimonials__item">
							<div className="testimonials__quote">
							mattis molestie a iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet nulla facilisi morbi tempus iaculis urna id volutpat lacus laoreet non curabituri 
							</div>
							<div className="testimonials__meta">
								<img className="testimonials__photo" src="https://placebear.com/253/253" />
								<div className="testimonials__info">
									<div className="testimonials__name">Youssef</div>
									<div className="testimonials__title">Front-End Wizard</div>
									<div className="testimonials__additional">Coder</div>
								</div>
							</div>
						</div>
						<div className="testimonials__item">
							<div className="testimonials__quote">
							tempor orci eu lobortis elementum nibh tellus molestie nunc non blandit massa enim nec dui nunc mattis enim ut tellus elementum sagittis vitae et leo duis ut diam quam nulla porttitor massa id neque aliquam vestibulum morbi blandit cursus riset
							</div>
							<div className="testimonials__meta">
								<img className="testimonials__photo" src="https://placebear.com/252/252" />
								<div className="testimonials__info">
									<div className="testimonials__name">Youssef</div>
									<div className="testimonials__title">Front-End Wizard</div>
									<div className="testimonials__additional">Coder</div>
								</div>
							</div>
						</div>
						</div>
					</div>
					<div className='d-flex mt-6 justify-content-center btn--testimonials'>
									<a href="/contact" className="btn btn-primary btn--3d btn--rg">Talk to Our Experts</a>
								</div>
					<img className="testimonials__img" src={`${homeChart}`} />

				</section> */}



				<section className="process">
					<div className="container">
						<div className="process__wrapper">
							<h2 className="overline overline--testimonials">How Our solution works</h2>
							<h2 className="section__title section__title--process">Act Confidently With Data-Driven Expertise and Tangible&nbsp;Results</h2>
							<div className="subtitle subtitle--home subtitle--process">
								At Bernie Yazzie Consulting, we understand the uncertainties and anxieties that come with implementing new solutions. That&apos;s why our approach is grounded in the power of data.
							</div>
							<div className="tiles">
								<div className="tile__item tile__item--1">
									<img
										className="tile__img"
										src={tileDive}
										alt=""
									/>
									<h3 className="tile__title">Dive</h3>
									<div className="subtitle subtitle--home">in to <b>demystify</b><br /> and analyze the data</div>
								</div>
								<div className="tile__item tile__item--2">
									<img
										className="tile__img"
										src={tileDiscover}
										alt=""
									/>
									<h3 className="tile__title">Discover</h3>
									<div className="subtitle subtitle--home">and <b>quantify</b> any<br /> potential vulnerabilities</div>
								</div>
								<div className="tile__item tile__item--3">
									<img
										className="tile__img"
										src={tileDevelop}
										alt=""
									/>
									<h3 className="tile__title">Develop</h3>
									<div className="subtitle subtitle--home">a plan of <b>action</b> that<br /> empowers leadership</div>
								</div>
							</div>
						</div>
					</div>
				</section>


				
				<section
					className="cta spearpoint--section py-0"
				>
					<div className="container ">
						<div
							className="cta__right cta__right--ssp  bg-fill--cta">
							<div>
								<h2 className="section__title section__title--cta">Track Your <br/>Facility&apos;s&nbsp;Vitals with&nbsp;Spearpoint.</h2>
							</div>
							<div className="spearpoint--section__images">
								<img className="spearpoint--section__images--1" src={`${dash2}`} />
								<img className="spearpoint--section__images--2" src={`${dash1}`} />
							</div>
							
						</div>
						<div className="cta__left">
							<div className="subtitle subtitle--home subtitle--cta">
							Spearpoint delivers unprecedented real-time insights into the financial health of your healthcare facility, leveraging data directly from the Resource and Patient Management System. Engineered to optimize your financial operations, Spearpoint offers proprietary software that enhances decision-making with precision and efficiency. Learn more about how to revolutionize your financial management practices with Spearpoint.
							</div>
							<div className='d-flex cta--button'>
								<Link to="/spearpoint" className="btn btn-primary btn--3d btn--rg btn--home">Learn More</Link>
							</div>
						</div>
					</div>
				</section>

				
				<section className="process">
					<div className="container">
						<div className="process__wrapper">
							<h2 className="section__title section__title--scope">Our Scope is the Entire Healthcare&nbsp;System</h2>
							<div className="subtitle subtitle--home subtitle--process">
							Your facility may need more than a guide. We offer full service support in multiple areas such as revenue cycle management, coding and billing, ARS, technical support, training and education, and more. We’re not here for short term success, we’re here to help your facility&nbsp;thrive&nbsp;through&nbsp;the years. 
							</div>
							<div className="tiles tiles--scope">
								<div className="tile__item tile__item--scope tile__item--1">
									<img
										className="tile__img"
										src={tileCycle}
										alt=""
									/>
									<h3 className="tile__title">Revenue Cycle Management</h3>
								</div>
								<div className="tile__item tile__item--scope tile__item--2">
									<img
										className="tile__img"
										src={tileCoding}
										alt="" 
									/>
									<h3 className="tile__title">Coding <br />Processes</h3>
								</div>
								<div className="tile__item tile__item--scope tile__item--3">
									<img
										className="tile__img"
										src={tileBilling}
										alt=""
									/>
									<h3 className="tile__title">Billing <br />Processes</h3>
								</div>
								<div className="tile__item tile__item--scope tile__item--4">
									<img
										className="tile__img"
										src={tileAccounts}
										alt=""
									/>
									<h3 className="tile__title">Accounts<br />Receivables</h3>
								</div>
							</div>
						</div>
					</div>
				</section>




				<section
					className="cta py-0">
					<div
						className="container container--cta"
						style={{ backgroundImage: `url(${bg2})` }}
					>
						<div
							className="cta__right  bg-fill--cta">
								<img className='cta__right__img' src={homeCTA}></img>
								<div className='cta__right__description'>Taking that first step of contacting Bernie Yazzie Consulting was what made all the difference. We knew right away that our facility was in good hands.</div>
								<div>
{/* 								<div>Rocky Boy Health Center</div>
								<div>Box Elder, MT</div> */}
								</div>
						</div>
						<div className="cta__left">
							<h3 className="overline overline--cta">How Change Happens</h3>
							<h2 className="section__title section__title--cta">Discover What is possible in as little as 15 minutes</h2>
							<div className="subtitle subtitle--home subtitle--cta">
								Everything begins with a simple conversation to make sure we are a good fit for your needs. Your confidentiality is prioritized as we discuss your facility&apos;s goals and get clarity on where we are starting from so we can lay out a plan of action.
							</div>
							<div className='d-flex cta--button'>
								<a href="/schedule" className="btn btn-primary btn--3d btn--rg btn--home">Schedule My Conversation</a>
							</div>
						</div>
					</div>
				</section>



				<section
					className="potential">
					<div className="container container--potential">

						<div className="potential__left">
							<h3 className="overline overline--potential">The change you’ve been looking&nbsp;for</h3>
							<h2 className="section__title section__title--potential">Create Thriving Facilities and Healthy&nbsp;Communities</h2>
							<div className="subtitle subtitle--home subtitle--potential">
								<p>In your journey towards success, we believe in empowering healthcare leaders like you. Through our expertise and guidance, we help create empowered leadership, identify weaknesses and threats, fix broken processes, reduce turnover, improve communication, and access effective training.</p>
								<p>Together, we&apos;ll unlock the full potential of your facility and create a thriving environment that fosters excellence and serves your community with quality healthcare.</p>
							</div>
							<div className='d-flex potential--button mt-4'>
								<a href="/contact" className="btn btn-primary btn--3d btn--rg btn--home">Unlock My Full Potential</a>
							</div>
						</div>
						<div className="potential__right">

							<div className="potential__images">
								<img className="potential__image potential__image--1" src={`${potential1}`} />
								<img className="potential__image potential__image--2" src={`${potential2}`} />
								<img className="potential__image potential__image--3" src={`${potential3}`} />
							</div>
						</div>
					</div>
					<img className="potential__border" src={`${homeBorder}`} />
				</section>



				<section className="rpms py-0">
					<div className="container max-800">
						<img
							className="rpms__img"
							src={homeRPMS}
							alt=""
						/>
						<h2 className="section__title mb-4">In the right hands, RPMS is the right&nbsp;tool.</h2>
						<div className="subtitle subtitle--home">
							<p>Our team are RPMS experts and know precisely how to demystify and analyze the data to recommend implementations that empower health system&nbsp;leadership.</p>
							<p>While RPMS has been around for a long time, HIPAA loves the fact that, to date, hackers have never infiltrated RPMS. Why risk exposure to hackers that love to infiltrate data to hold for&nbsp;ransom?</p>
							<p>Other systems can cost millions with numerous other expenses, but RPMS is not only free it also has extensive free training and support from&nbsp;IHS.</p>
						</div>
						<div className='d-flex rpms--button align-center justify-content-center mt-4'>
							<a href="/contact" className="btn btn-primary btn--3d btn--rg btn--home">Book Your Call</a>
						</div>
					</div>
				</section>
			</div>

			<Footer />
		</div>
	);
};

export default Site;
